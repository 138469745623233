var render = function render(){var _vm=this,_c=_vm._self._c;return (
    _vm.matterTimeAllocation &&
    _vm.matterTimeAllocation.time_allocation ==
      _vm.MatEvaStuEnvTimAll.time_allocation &&
    (_vm.matterTimeAllocation.hours > 0 || _vm.MatEvaStuEnvTimAll.hours > 0)
  )?_c('div',[_c('div',{staticClass:"d-flex mb-3"},[_c('div',{staticClass:"w-10"},[_c('b-form-input',{key:_vm.MatEvaStuEnvTimAll.id + '-' + _vm.key_form_input,attrs:{"state":_vm.timeAvailable >= 0,"type":"number","min":"0","max":_vm.maxTimeAvailable,"size":"sm"},on:{"update":_vm.updateInput},model:{value:(_vm.tmp_mat_eva_stu_env_tim_all.hours),callback:function ($$v) {_vm.$set(_vm.tmp_mat_eva_stu_env_tim_all, "hours", $$v)},expression:"tmp_mat_eva_stu_env_tim_all.hours"}})],1),_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(
        `${
          !_vm.isValidStudyEnvironment
            ? 'Son horas que no están asociadas a los ' +
              _vm.$getVisibleNames(
                'mesh.studyenvironment',
                false,
                'Ambiente de Aprendizaje'
              ) +
              ' seleccionados.'
            : ''
        }`
      ),expression:"\n        `${\n          !isValidStudyEnvironment\n            ? 'Son horas que no están asociadas a los ' +\n              $getVisibleNames(\n                'mesh.studyenvironment',\n                false,\n                'Ambiente de Aprendizaje'\n              ) +\n              ' seleccionados.'\n            : ''\n        }`\n      ",modifiers:{"v-secondary":true,"noninteractive":true}}],staticClass:"ml-2",class:{ 'text-red': !_vm.isValidStudyEnvironment }},[(_vm.timeAllocation)?_c('span',{staticClass:"align-middle"},[_vm._v(" "+_vm._s(_vm.timeAllocation.name)+"("+_vm._s(_vm.matterTimeAllocation.hours)+"Hrs) ")]):_vm._e()]),(_vm.timeAvailable >= 0)?[_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(
          `${!_vm.isValidStudyEnvironment ? '' : ''}`
        ),expression:"\n          `${!isValidStudyEnvironment ? '' : ''}`\n        ",modifiers:{"v-secondary":true,"noninteractive":true}}],staticClass:"ml-2"},[_c('span',{staticClass:"align-middle"},[_vm._v("(Disponible: "+_vm._s(_vm._f("Round")(_vm.timeAvailable))+"Hrs)")])])]:[_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(
          `${!_vm.isValidStudyEnvironment ? '' : ''}`
        ),expression:"\n          `${!isValidStudyEnvironment ? '' : ''}`\n        ",modifiers:{"v-secondary":true,"noninteractive":true}}],staticClass:"ml-2 text-red"},[_c('span',{staticClass:"align-middle"},[_vm._v("(Excedente: "+_vm._s(_vm._f("Round")((_vm.timeAvailable * -1)))+"Hrs)")])])],(_vm.show_button_delete)?[_c('button-delete',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.topright.noninteractive",value:(
          ` Eliminar Hora ${
            _vm.study_environment_id == null
              ? 'Sin ' +
                _vm.$getVisibleNames(
                  'mesh.studyenvironment',
                  false,
                  'Ambiente de Aprendizaje'
                )
              : ''
          }`
        ),expression:"\n          ` Eliminar Hora ${\n            study_environment_id == null\n              ? 'Sin ' +\n                $getVisibleNames(\n                  'mesh.studyenvironment',\n                  false,\n                  'Ambiente de Aprendizaje'\n                )\n              : ''\n          }`\n        ",modifiers:{"v-secondary":true,"topright":true,"noninteractive":true}}],staticClass:"ml-1 text-danger",attrs:{"size":"sm"},on:{"click":function($event){return _vm.deleteHour(_vm.MatEvaStuEnvTimAll.id)}}})]:_vm._e()],2)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }